"use client";

import React, { useCallback, useContext, useEffect, useState } from "react";
import s from "./Modal.module.scss";
import DownloadBrochureForm from "./DownloadForm";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/navigation";
import { GAEvent, GA_EVENTS_PAYLOAD, IGAEvent, ga_events_mapping } from "@/helpers/gaEvents";
import { pushToDataLayer } from "@/helpers/gtag";
import CommonButton from "@/components/common/Analytics/CustomButton";
import { PROGRAMS } from "@molecules/ProgramMenu/constants";
import { usePlatformAndOS } from "@/helpers/usePlatformAndOS";
import { useDeviceInfo } from "@/helpers/userDeviceType";
import { getGAPayload } from "@/helpers/getGAPayload";
import { StorageService } from "@/helpers/storage.service"
import { useOTPContext } from "@/components/sections/CommonModals/context";
import { getGlobalStateValue, updateStateSession } from "@/context/utils";

type TGaOtherPayload = {
  platform: string;
  deviceType: string;
  version: string;
  pageTitle: string;
  pageReferrer: string;
  pageUrl: string;
};

type TGaConversionData = {
  enhanced_conversion: {
    emailId: string;
    phoneNumber: string;
  };
  enhanced_conversion_data: {
    emailId: string;
  };
};

declare var programDetails
const Downloadfile = () => {
	const path = usePathname()
	const router = useRouter()

	useEffect(() => {
		const aElement = document.createElement("a")
		aElement.setAttribute(
			"download",
			(programDetails.Heading || "Brochure") + ".pdf"
		)
		aElement.href = `${programDetails.Brochure}`
		aElement.setAttribute("target", "_blank")
		aElement.click()

		let timeout = setTimeout(() => {
			router.replace(path, {
				scroll: false,
			})
		}, 4000)
		return () => {
			timeout && clearTimeout(timeout)
		}
	}, [])
	return null
}

export default function DownloadBrochure(props) {
  const {isMobile, onClose, specializationText, Custom10PgType } = props
  const pathName = usePathname();
	const searchParams = useSearchParams();
	const [message, setMessage] = useState(null)
	const [formResponse, setFormResponse] = useState(null);
  const [formValuesData, setFormValuesData] = useState(null);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageReferrer, setPageReferrer] = useState<string>('');
  const [cancelGaData, setCancelGaData] = useState<IGAEvent | null>(null);
  const [proceedGaData, setProceedGaData] = useState<IGAEvent | null>(null);

  const { leadFailedComp } = useOTPContext()

  const { 
    platform = "",
    version = "" 
  } = usePlatformAndOS();
  const deviceType = useDeviceInfo();
	const [isOtpVerified, setIsOtpVerified] = useState(false);

    
  const {
    PROGRAM_TAB_LEAD_VERIFIED,
    PROGRAM_CARD_LEAD_VERIFIED,
    PROGRAM_LEAD_VERIFIED,
    PROGRAM_TAB_CANCEL,
    PROGRAM_CARD_CANCEL,
    PROGRAM_CANCEL,
    PROGRAM_TAB_PROCEED,
    PROGRAM_CARD_PROCEED,
    PROGRAM_PROCEED,
    PROGRAM_TAB_DOWNLOAD_BROCHURE_URL,
    PROGRAM_CARD_DOWNLOAD_BROCHURE_URL,
    PROGRAM_DOWNLOAD_BROCHURE_URL
  } = GAEvent;

  const gaOtherPayload:TGaOtherPayload = {
    platform,
    deviceType,
    version,
    pageTitle,
    pageReferrer,
    pageUrl : typeof window !== 'undefined' ? window.location.href : '',
  }

  const gaConversionData:TGaConversionData = {
    enhanced_conversion : {
      emailId : formValuesData?.email || "",
      phoneNumber : formValuesData?.phone || "",
    },
    enhanced_conversion_data : {
      emailId : formValuesData?.email || "",
    }
  }

  function getGaSessionData(): {
    isMbaPage?: boolean
    isNavbarClick?: boolean
  } | null {
    return StorageService.get("programDetailsGAInfo") ?? {}
  };

  useEffect(() => {
    updateStateSession({
      formSectionName : "Download Brochure",
    });
  }, []);

  useEffect(() => {
    const handleTitleChange = () => {
      setPageTitle(document.title);
    };
    setPageReferrer(document.referrer);
    setPageTitle(document.title);
    document.addEventListener('titlechange', handleTitleChange);
  
    return () => {
      document.removeEventListener('titlechange', handleTitleChange);
    };
	}, []);

  // TODO: Shift all the GA events to a utility function
	useEffect(()=>{
	  if (formResponse !== null && formResponse?.link !== "") {
      const { isMbaPage = false, isNavbarClick } = getGaSessionData()
      if (ga_events_mapping?.[pathName] || isMbaPage) {
        const eventPayload =
          isNavbarClick !== undefined
            ? isNavbarClick
              ? GA_EVENTS_PAYLOAD.get(PROGRAM_TAB_LEAD_VERIFIED)
              : GA_EVENTS_PAYLOAD.get(PROGRAM_CARD_LEAD_VERIFIED)
            : GA_EVENTS_PAYLOAD.get(PROGRAM_LEAD_VERIFIED)
  
        const gaData = {
          ...eventPayload,
          ...gaConversionData,
        };
        const gaPayload = getGAPayload(gaData, searchParams, gaOtherPayload);
        pushToDataLayer(gaPayload);
      }
    }
	},[formResponse]);

  useEffect(() => {
    const setGaData = (
      isNavbarClick: boolean | undefined,
      defaultPayload: GAEvent,
      tabPayload: GAEvent,
      cardPayload: GAEvent,
      pageTypeDefault: string,
      pageTypeTab: string,
      pageTypeCard: string
    ): IGAEvent => {
      const data = isNavbarClick !== undefined
        ? {
          ...GA_EVENTS_PAYLOAD.get(isNavbarClick ? tabPayload : cardPayload),
          pageType: isNavbarClick ? pageTypeTab : pageTypeCard,
        }
        : {
          ...GA_EVENTS_PAYLOAD.get(defaultPayload),
          pageType: pageTypeDefault,
        };

      return { ...data, ...gaConversionData };
    };
  
    const { isMbaPage = false, isNavbarClick } = getGaSessionData()
  
    if (ga_events_mapping?.[pathName] || isMbaPage) {
      try {
        const cancelGaData = setGaData(
          isNavbarClick,
          PROGRAM_CANCEL,
          PROGRAM_TAB_CANCEL,
          PROGRAM_CARD_CANCEL,
          "Program Details",
          "Program Nav",
          "Program Card"
        )

        setCancelGaData(cancelGaData);

        const proceedGaData = setGaData(
          isNavbarClick,
          PROGRAM_PROCEED,
          PROGRAM_TAB_PROCEED,
          PROGRAM_CARD_PROCEED,
          "Program Details",
          "Program Nav",
          "Program Card"
        );

        setProceedGaData(proceedGaData);
      } catch (error) {
        console.error("Error setting GA data", error);
      }
    }
  }, [formValuesData]);

	const path = usePathname();
	
  const handleDownloadGaClick = () => {
    const { isMbaPage = false, isNavbarClick } = getGaSessionData();
    
    if (ga_events_mapping?.[pathName] || isMbaPage) {
      const eventPayload =
        isNavbarClick !== undefined
          ? isNavbarClick
            ? GA_EVENTS_PAYLOAD.get(PROGRAM_TAB_DOWNLOAD_BROCHURE_URL)
            : GA_EVENTS_PAYLOAD.get(PROGRAM_CARD_DOWNLOAD_BROCHURE_URL)
          : GA_EVENTS_PAYLOAD.get(PROGRAM_DOWNLOAD_BROCHURE_URL)
  
      const gaData = {
        ...eventPayload,
        ...gaConversionData,
      };
  
      const otherPayloads = {
        ...gaOtherPayload,
        pageUrl: programDetails?.Brochure,
      };
  
      const gaPayload = getGAPayload(gaData, searchParams, otherPayloads);
      pushToDataLayer(gaPayload);	
    }
  };

  const handleOtpValidation = useCallback(() => {
		setIsOtpVerified(true);
	}, []);

	return (
    <div className={`${s.root}`}>
      {!isMobile && <img src="/_a/strapi/man-wearing-striped-shirt-uses-laptop-work 1.png" />}
      {isOtpVerified && message!==null ? null : (
        <DownloadBrochureForm
          setMessage={setMessage}
          setFormResponse={setFormResponse}
          gaOtherPayloadInfo={gaOtherPayload}
          setFormValuesData={setFormValuesData}
          isOtpVerified={handleOtpValidation}
          specializationText={specializationText}
          Custom10PgType={Custom10PgType}
        />
      )}
      
      {isOtpVerified && message && (
        <div className={`flex flex-col justify-center items-center my-4`}>
          <img className={s.icon} src="/_a/icons/thumbs-up.svg" />
          <div className={`${s.mtitle} font-baskervville`}>{message?.title}</div>
          <div className={`${s.mtext} font-raleway`}>
            {message?.text}
            <p className={`${s.downloadLink} font-raleway`}>
              <a
                onClick={
                  ()=>{
                    pushToDataLayer({
                      event: "thank_you_card_cta_interaction",
                      cta_text: "Download Brochure",
                      section_name: getGlobalStateValue('parentSection'),
                      page_type: getGlobalStateValue('pageType'),
                      page_url : window?.location?.href || "NA"
                    })
                    path === PROGRAMS[1].url ? handleDownloadGaClick : undefined
                  }
                }
                href={programDetails.Brochure}
                download={(programDetails.Heading || "Brochure") + ".pdf"}
                target="_blank"
              >
                Download Brochure
              </a>
            </p>
          </div>
          <div className="flex gap-x-4 my-4">
          {
            leadFailedComp && (
              <>
                <CommonButton
                  GAData={cancelGaData}
                  isEventOnly
                  GADataNew={{
                    event: "thank_you_card_cta_interaction",
                    cta_text: "Cancel",
                    section_name: getGlobalStateValue('parentSection'),
                    page_type: getGlobalStateValue('pageType'),
                    page_url : true
                  }}
                  size="l"
                  bg="o"
                  className="flex justify-center items-center text-xs"
                  type={"button"}
                  closeModal={true}
                >
                  CANCEL
                </CommonButton>
                <CommonButton
                  GAData={proceedGaData}
                  isEventOnly
                  GADataNew={
                    {
                     event: "thank_you_card_cta_interaction",
                     cta_text: "Proceed",
                     section_name: getGlobalStateValue('parentSection'),
                     page_type: getGlobalStateValue('pageType'),
                     page_url : true
                     }
                   }
                  href={`${formResponse?.link}${searchParams.get("tab") ? "&e=3" : "&e=2"}`}
                  size="l"
                  color="b"
                  icon="/_a/icons/left-arrow-white.svg"
                  className="flex justify-center items-center text-xs"
                >
                  PROCEED
                </CommonButton>
              </>
            )
          }
          </div>
        </div>
      )}
    </div>
  )
}