"use client";

import dynamic from "next/dynamic";
import { Dispatch, SetStateAction, useState } from "react";
import type { LoginTypes } from "@/types/modal";
import LoginWithEmail from "./LoginWithEmail"

import s from "./LoginModal.module.scss"

const ForgotPassword = dynamic(() => import("./ForgotPassword"));
const LoginwithOTP = dynamic(() => import("./LoginwithOTP"));
const mobileImgLink = "https://amityonline.com/_s/Login_Modal_Mobile_Image_f7e10019d6.png";
const desktopImgLink = "https://amityonline.com/_s/man_wearing_striped_shirt_uses_laptop_work_1_03b384caee.png";

const FormSwitcher = ({
  tab,
  setMessage,
  setTab,
}: {
  tab: LoginTypes
  setMessage: Dispatch<SetStateAction<any>>
  setTab: (type: string) => void
}) => {


  if (tab.forgetPass)
    return <ForgotPassword setMessage={setMessage} setTab={setTab} />
  if (tab.withOTP) return <LoginwithOTP setTab={setTab} />
  return <LoginWithEmail setTab={setTab} />
}

const initialTabState: LoginTypes = {
	withEmail: true,
	withOTP: false,
	forgetPass: false
}

export default function LoginModal(props) {
  const [tab, setTab] = useState<LoginTypes>(initialTabState)
	const [message, setMessage] = useState(null);
	const { isMobile } = props

  const setSinglePage = (type: string)=>{
		let changePage: LoginTypes = {...tab}
		for(let key in tab){
			if(key===type)
				changePage[key] = true
			else changePage[key]= false
		}
		setTab(changePage)
	}

  return (
    <div className={s.root}>
      <img
        src={isMobile ? mobileImgLink : desktopImgLink}
        style={{ objectFit: "fill" }}
      />
      <FormSwitcher tab={tab} setMessage={setMessage} setTab={setSinglePage} />
      {message && (
        <div className={s.message}>
          <img className={s.icon} src="/_a/icons/thumbs-up.svg" />
          <div className={`${s.mtitle} font-baskervville`}>{message.title}</div>
          <div className={`${s.mtext} font-raleway`}>{message.text}</div>
        </div>
      )}
    </div>
  )
}
