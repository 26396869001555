"use client";

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import s from "./Modal.module.scss";
import clsx from "clsx";
import { ModalTypes } from "@/types/modal";
import { updateStateSession } from "@/context/utils";

export function ModalDialog({
  children,
  className,
  size = null,
  onClose,
  modalType
}: React.PropsWithChildren<any>) {

  return (
    <div
      className={clsx(
        'bg-white',
        (modalType === ModalTypes.CallUsModal || modalType === ModalTypes.PreDegreeModal)  && 'padding-0-imp',
         modalType === ModalTypes.PreDegreeModal ? s.dialogNew: s.dialog,
        className,
        {
          [s.xl]: size == "xl",
        },
      )}
    >
      <svg
        onClick={(e) => {
          onClose();
          // reset global store
          updateStateSession({
            parentSection: ""
          })
        }}
        className={s.close}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_380_520)">
          <path
            d="M18.2648 20.9813L11.6211 14.3376L4.9774 20.9813L2.76284 18.7667L9.40653 12.123L2.76284 5.47935L4.9774 3.26479L11.6211 9.90848L18.2648 3.26479L20.4794 5.47935L13.8357 12.123L20.4794 18.7667L18.2648 20.9813Z"
            fill="#1A325D"
          />
        </g>
        <defs>
          <clipPath id="clip0_380_520">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {children}
    </div>
  );
}

const Modal = ({ modalId, parentId = "_modal", children }) => {
  const [el, setEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    let scrollPosition;
    let el = document.getElementById(parentId) as HTMLElement;
    let m = () => {
      scrollPosition = document.body.scrollTop;
      if (el) {
        setEl(el);
      }
    };
    if (modalId !== "") m();
    else {
      setEl(null);
      document.body.scrollTo(0, scrollPosition);
    }

    let listner = (e) => {
      console.log(e);
      console.log(window.location.hash);
      console.log("The hash has changed!");
    };

    window.addEventListener("hashchange", listner, false);

    return () => {
      window.removeEventListener("hashchange", listner, false);
    };
  }, [modalId, parentId]);

  return el ? createPortal(<div className={s.root}>{children}</div>, el) : null;
};

export default Modal;
